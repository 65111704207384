<template>
  <div class="page-class page-user-list">
    <h1 class="page-title text-center">{{ title }}</h1>
    <checklist-box
      :columns="columns"
      :rows="events"
      :baseUrl="baseUrl"
      :loading="loading"
      :showAdd="true"
      :showActiveEvents="ifTest()"
      :showToolbar="true"
      :showPagination="true"
      :pagination="pagination"
      :filterOptions="filterOptions"
      :filtereventOptions="filtereventOptions"
      :searchItems="searchItems"
      addText="Create new Event"
      emptyText="No events found. Please add new event."
      activeEventsText="See Active Events of App"
      @add="onAdd"
      @delete="onDelete"
      @edit="onEdit"
      @activeEvents="seeEventPages"
    >
    </checklist-box>
  </div>
</template>

<script>
import moment from 'moment';
import { mapState, mapActions } from 'vuex';
import ChecklistBox from '@/components/ChecklistBox.vue';
import router from '@/router';
import _ from 'lodash';
import Vue from 'vue';

export default {
  name: 'EventPlanList',
  components: { ChecklistBox },
  metaInfo() {
    return {
      title: 'Event Plan List',
      meta: [
        {
          name: 'description',
          content: `Manage Event Plan List`
        }
      ]
    };
  },
  mounted() {
    if (_.isEmpty(this.$route.query)) {
      this.listEvents({
        query: {
          ...this.$route.query
        }
      });
    } else {
      this.listEvents({ query: this.$route.query });
    }
  },
  beforeRouteUpdate(to, from, next) {
    this.listEvents({ query: to.query });
    next();
  },
  data() {
    return {
      title: 'Event Plan List',
      columns: [
        {
          type: 'row_num',
          headerText: '#',
          class: { ' text-center': true },
          textKey: 'index',
          width: '3%'
        },
        {
          type: 'string',
          headerText: 'Name',
          class: { 'text-center': true },
          textKey: 'name',
          width: '12%'
        },
        {
          type: 'string',
          headerText: 'Active',
          class: { 'text-center': true },
          textKey: 'active',
          width: '6%'
        },
        {
          type: 'string',
          headerText: 'Role',
          class: { 'text-center': true },
          textKey: 'roles',
          width: '10%'
        },
        {
          type: 'string',
          headerText: 'Start Date',
          class: { 'text-center': true },
          textKey: 'startDate',
          width: '9%'
        },
        {
          type: 'string',
          headerText: 'End Date',
          class: { 'text-center': true },
          textKey: 'endDate',
          width: '9%'
        },
        {
          type: 'string',
          headerText: 'Frequency',
          class: { 'text-center': true },
          textKey: 'frequency',
          width: '6%'
        },
        {
          type: 'string',
          headerText: 'Region',
          class: { 'text-center': true },
          textKey: 'regions',
          width: '8%'
        },
        {
          type: 'string',
          headerText: 'Cluster',
          class: { 'text-center': true },
          textKey: 'clusters',
          width: '5%'
        },
        {
          type: 'string',
          headerText: 'Store Id',
          class: { 'text-center': true },
          textKey: 'storeids',
          width: '9%'
        },
        {
          type: 'string',
          headerText: 'Formats',
          class: { 'text-center': true },
          textKey: 'formats',
          width: '9%'
        },

        {
          type: 'functions',
          headerText: 'Functions',
          class: { 'text-center': true },
          width: '14%',
          functions: {
            edit: true,
            delete: true
          }
        }
      ],
      searchItems: ['Name', 'Store Id'],
      filterOptions: [
        { value: null, text: 'Select Filter' },
        { value: 'Active-True', text: 'Active-True' },
        { value: 'Active-False', text: 'Active-False' }
      ],
      filtereventOptions: [
        { value: null, text: 'Select Events' },
        { value: 'false', text: 'Current-Events' },
        { value: 'true', text: 'Expired-Events' }
      ]
    };
  },
  computed: {
    ...mapState('events', ['loading', 'baseUrl', 'events', 'pagination']),
    ...mapState('auth', ['user']),
    ...mapState('darkMode', ['isDarkMode'])
  },
  methods: {
    ...mapActions('events', ['listEvents', 'deleteOne']),
    onAdd() {
      router.push('/event-plan/new');
    },
    ifTest() {
      const isTest = this.user.roles.filter(role => role.name === 'TEST_ROLE');
      if (isTest.length) {
        return true;
      }
      return false;
    },
    seeEventPages() {
      router.push('/active-event-list');
    },
    onEdit({ row }) {
      router.push(`/event-plan/${row.id}`);
    },
    onDelete({ row }) {
      Vue.swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this.",
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
        background: this.isDarkMode ? '#464d54' : '#ffffff',
        customClass: {
          title: this.isDarkMode ? 'text-light' : 'text-dark',
          content: this.isDarkMode ? 'text-light' : 'text-dark'
        },
        preConfirm: () => {
          this.deleteOne({
            id: row.id
          });
        }
      });
    }
  }
};
</script>
